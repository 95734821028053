export default {
  props: {
    identifier: {
      type: String,
      required: true,
    },
    mobileIdentifier: {
      type: String,
      default: undefined,
    },
    isLoggedMediclub: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    block () {
      return this.$store.state.staticBlock.blocks[this.identifier]
    },

    mobileBlock () {
      return this.$store.state.staticBlock.blocks[this.mobileIdentifier]
    },

    isJson () {
      return this.block?.isJson ?? false
    },

    blockContent () {
      let content

      try {
        content = JSON.parse(JSON.stringify(this.block.contentJson))
      } catch {
        content = ''
      }

      return content
    },

    mobileBlockContent () {
      let content

      try {
        content = JSON.parse(JSON.stringify(this.mobileBlock.contentJson))
      } catch {
        content = ''
      }

      return content
    },
  },
}
